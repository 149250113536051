@import "../../styles/abstracts/animations";
@import "../../styles/abstracts/mixins";
@import "../../styles/abstracts/variables";

.u-typography-primary {
  display: inline-block;
  color: #263351;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
}

.u-typography-primary--sm {
  font-size: 0.9rem;
}

.u-typography-primary--xs {
  font-size: 0.8rem;
}

.u-typography-primary--highlight {
  display: inline-block;
  font-weight: 700;
}

.u-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.u-center-vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.u-align-items {
  display: flex;
  align-items: center;
}

.u-margin-bottom-lg {
  margin-bottom: 50px;
}

.u-margin-bottom-md {
  margin-bottom: 35px;
}

.u-margin-bottom-sm {
  margin-bottom: 20px;
}

.u-margin-top-lg {
  margin-top: 50px;
}

.u-margin-top-xl {
  margin-top: 65px;
}

.u-margin-top-md {
  margin-top: 35px;
}

.u-margin-top-xs {
  margin-top: 7px;
}

.u-margin-top-25 {
  margin-top: 25px;
}

.u-margin-top-sm {
  margin-top: 20px;
}

.u-padding-top-lg {
  padding-top: 50px;
}

.container-100 {
  width: 100%;
  position: relative;
}

.u-margin-right-xs {
  margin-right: 10px;
}

.u-margin-top-minus-20 {
  margin-top: -20px;
}

.u-break-overflown-word {
  @include break-overflown-word;
}
